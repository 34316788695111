import React from "react";
import DataTable from "./DataTable";

const App = () => {
  return (
    <div style={{padding: "25px"}}>
      <h1>Недавние поточки</h1>
      <DataTable />
      <p>
      Стримы автоматически записываются и хранятся на сервере в течении 5 дней, после чего удаляются. Посмотреть поток можно скачав его по ссылке или скопировав ссылку и вставив в медиаплеер (например VLC)
      </p>
    </div>
  );
};

export default App;
