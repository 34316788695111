import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  Button
} from "@material-ui/core";

const handleCopyText = (text) => {
  navigator.clipboard.writeText(text);
};

function parseString(inputString) {
  const regex = /^(\w+)_(\d{4}-\d{2}-\d{2})~(\d{2}-\d{2}-\d{2}[A-Z]{0,4})_([_\-a-zA-Z0-9]+)(\.mkv|\.mp4)$/gm;
  let m;
  let groups = [];

  while ((m = regex.exec(inputString)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }
      
      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        if (groupIndex === 0) {
          return;
        }
        groups.push(match);
      });
  }

  const channelNameRaw = groups[0];
  let channelName = "";
  if (channelNameRaw === "citinez") {
    channelName = "Citinez";  
  } else if (channelNameRaw === "citinez2") {
    channelName = "в гостях у хряка";
  } else {
    channelName = channelNameRaw + " (неизвестный канал)";
  }
  const date = groups[1];
  const time = groups[2];
  const youtubeCode = groups[3];

  const title = `Поточек от ${date}`;
  const youtubeLink = `https://youtu.be/${youtubeCode}`;
  const videoLink = `https://pub-791bac55a59e4e81b027a5e7f6f4b861.r2.dev/${inputString}`;

  let obj = {
    channel_name: channelName,
    title: title,
    youtube_link: youtubeLink,
    video_link: videoLink,
  };
  console.log(obj);
  return obj;
}

const DataTable = () => {
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    fetchData();
  }, []);

  

  const fetchData = async () => {
    try {
      const response = await axios.get("https://cr-list-files.piges.workers.dev/");
      console.log(response);
      let fixedData = [];
      for (let value of response.data) {
        if (value.key.startsWith("reznik")) {
          continue;
        }
        try {
          fixedData.push(parseString(value.key));
        } catch (error) {
          console.warn(`${value.key} not parsed`)
        }
      }
      setData(fixedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const sortedData = data.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return b[orderBy] > a[orderBy] ? 1 : -1;
    }
  });

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "title"}
                direction={orderBy === "title" ? order : "asc"}
                onClick={() => handleSort("title")}
              >
                Название
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "youtube_link"}
                direction={orderBy === "youtube_link" ? order : "asc"}
                onClick={() => handleSort("youtube_link")}
              >
                Ссылка на Youtube
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "channel_name"}
                direction={orderBy === "channel_name" ? order : "asc"}
                onClick={() => handleSort("channel_name")}
              >
                Название канала
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "video_link"}
                direction={orderBy === "video_link" ? order : "asc"}
                onClick={() => handleSort("video_link")}
              >
                Ссылка на запись
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.title}</TableCell>
              <TableCell>
                <a href={item.youtube_link} target="_blank">Ссылка</a>
              </TableCell>
              <TableCell>{item.channel_name}</TableCell>
              <TableCell><Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCopyText(item.video_link)}
                >
                  Скопировать ссылку
                </Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default DataTable;
